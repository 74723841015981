<template>
	<div class="mx-auto" style="max-width: 800px">
		<v-row class="mt-1 mb-2" no-gutters>
			<div class="d-flex align-center justify-space-between text-left w-full">
				<router-link
					:to="{
						name: 'mypage-market-item',
					}"
					class="text-decoration-none"
				>
					<h3 style="font-weight: 500">
						<v-icon style="position: relative; bottom: 2px">
							mdi-card-multiple-outline
						</v-icon>
						주문 내역
					</h3>
				</router-link>
				<v-spacer />
				<v-select
					:items="orderStatuses"
					v-model="selected"
					item-text="name"
					item-value="value"
					hide-details
					@change="changeOrderStatus"
					dense
					outlined
					style="max-width: 150px"
				/>
			</div>
		</v-row>
		<v-row no-gutters>
			<my-page-market-order-list :orders="orders" />
		</v-row>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { orderStatus } from '@/helpers'
import { useRouter } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'

import MyPageMarketOrderList from './MyPageMarketOrderList.vue'

import MarketOrderService from '@/services/MarketOrderService'

export default {
	components: {
		MyPageMarketOrderList,
	},
	setup() {
		const { router } = useRouter()
		const orders = ref([])
		const selected = ref('')
		const orderStatuses = ref([
			{
				name: '전체',
				value: '',
			},
			...Object.values(orderStatus),
		])

		const getMarketOrders = async (status = '') => {
			try {
				orders.value = await MarketOrderService.listMarketOrders(status)
			} catch (e) {
				await warningSwal('잘못된 요청입니다')
				router.go(-1)
			}
		}
		getMarketOrders()

		const changeOrderStatus = async status => {
			await getMarketOrders(status)
		}

		return {
			orders,
			selected,
			orderStatuses,

			changeOrderStatus,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
