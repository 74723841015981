<template>
	<div class="w-full">
		<div v-if="orders" class="pb-16">
			<v-card
				outlined
				v-for="(order, index) in orders"
				:key="'order' + index"
				:class="isSmAndDown ? 'px-3 my-3 pb-3' : 'px-7 mt-1 mb-4 pb-3'"
			>
				<v-card-title class="px-0 d-flex justify-space-between">
					<div class="d-flex align-center">
						<div class="status-wrapper">
							{{ resolveOrderStatus(order) }}
						</div>
						<div class="text-base font-weight-bold">
							{{ order.title }}
						</div>
					</div>
					<div class="d-flex align-center">
						<div :class="isSmAndUp ? 'd-block' : 'd-none'">
							<v-btn @click="deleteMarketOrder(order)" v-if="order.paid" icon>
								<v-icon>mdi-close-circle-outline</v-icon>
							</v-btn>
							<div v-else>
								<v-btn
									:to="{
										name: 'mypage-market-order-payment',
										params: {
											orderId: order.id,
										},
									}"
									class="mr-1"
									color="primary"
									v-if="order.status === 'Ready'"
								>
									결제하기
								</v-btn>
								<v-btn
									@click="cancelOrderMarketItem(order)"
									color="secondary"
									outlined
									v-if="!order.canceled"
								>
									주문 취소하기
								</v-btn>
							</div>
						</div>
					</div>
				</v-card-title>
				<v-card-text
					style="padding: 1.6rem; background-color: #f8f8fa"
					class="py-0"
					:class="{ 'px-3': isSmAndDown }"
				>
					<v-list style="background-color: #f8f8fa">
						<v-list-item
							v-if="order && order.vaccountName && order.vaccountNumber"
						>
							<v-list-item-content style="flex-wrap: nowrap">
								<v-list-item-subtitle class="text-left">
									입금 은행
								</v-list-item-subtitle>
								<v-list-item-title class="text-right">
									<span class="font-weight-bold">
										{{ order.vaccountName }}
										<br />
										{{ order.vaccountNumber }}
									</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item>
							<v-list-item-content style="flex-wrap: nowrap">
								<v-list-item-subtitle class="text-left">
									주문 금액
								</v-list-item-subtitle>
								<v-list-item-title
									class="text-right"
									v-if="order && order.amount"
								>
									<span class="font-weight-bold">
										{{ order.amount | commaFormat }}
									</span>
									원
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content style="flex-wrap: nowrap">
								<v-list-item-subtitle class="text-left">
									주문 일자
								</v-list-item-subtitle>
								<v-list-item-title
									class="text-right"
									v-if="order && order.orderedAt"
									:class="{ 'text-sm': isSmAndDown }"
								>
									{{ order.orderedAt | dateUTCToKSTSibunchoFormat }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-actions class="pt-4 pb-0 px-0 d-flex justify-end">
					<div v-if="order.paid">
						<span class="apply-refund" @click="requestRefund(order)">
							환불 신청
						</span>
					</div>
					<div :class="isSmAndUp ? 'd-none' : 'd-flex justify-end'" v-else>
						<v-btn
							:to="{
								name: 'mypage-market-order-payment',
								params: {
									orderId: order.id,
								},
							}"
							class="mr-1"
							color="primary"
							v-if="order.status === 'Ready'"
						>
							결제하기
						</v-btn>
						<v-btn
							@click="cancelOrderMarketItem(order)"
							color="secondary"
							outlined
							class="ml-0"
							v-if="!order.canceled"
						>
							주문 취소하기
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</div>
		<div v-else>
			<div class="text-center mt-16">
				<p>주문 내역이 없습니다</p>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'
import { resolveOrderStatus } from '@core/utils/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketItemService from '@/services/MarketItemService'
import MarketOrderService from '@/services/MarketOrderService'

export default {
	components: {},
	props: {
		orders: {
			type: Array,
			required: true,
		},
	},
	setup() {
		const $vuetify = getVuetify()

		const cancelOrderMarketItem = async item => {
			const confirm = await confirmSwal(
				` ${item.title} 주문을<br/> 취소하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketItemService.cancelOrderMarketItem(item.id)
					successSwal({
						html: '주문이 취소되었습니다',
						allowOutsideClick: false,
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '주문을 취소하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const deleteMarketOrder = async order => {
			const confirm = await confirmSwal(
				` ${order.title} 주문 내역을<br/> 삭제하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketOrderService.deleteMarketOrder(order.id)
					successSwal({
						html: '주문 내역이 삭제되었습니다',
						allowOutsideClick: false,
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '주문 내역을 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const requestRefund = async order => {
			const confirm = await confirmSwal(
				` ${order.title} <br/> 주문을 환불 신청하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketOrderService.requestRefund(order.id)
					successSwal({
						html: '환불 신청되었습니다',
						allowOutsideClick: false,
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '환불을 신청하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		return {
			isSmAndUp,
			isSmAndDown,

			resolveOrderStatus,

			requestRefund,
			deleteMarketOrder,
			cancelOrderMarketItem,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.status-wrapper {
	font-size: 12px;
	margin-right: 1rem;
	padding: 4px 6px;
	border: 0.1rem solid #ed234b;
	color: #ed234b;
	max-width: 64px;
}

.apply-refund {
	color: #9b9ba0;
	font-size: 13px;
	text-decoration: underline;
	cursor: pointer;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
